import React from 'react';

const MainDashboard = () => {
  return (
    <div className='h-full p-[2.5vh]'>
      <h1 className='text-[2.6vh] text-white text-left'>This is Main Dashboard</h1>
    </div>
  )
}

export default MainDashboard;