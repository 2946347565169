import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  // Create an array of page numbers to display
  const pageNumbers: (number | string)[] = [];

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (currentPage <= 3) {
      pageNumbers.push(1, 2, 3, "...", totalPages);
    } else if (currentPage > totalPages - 3) {
      pageNumbers.push(
        1,
        "...",
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages
      );
    } else {
      pageNumbers.push(
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages
      );
    }
  }

  return (
    <div className="flex justify-center mt-[2vh] items-center">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-[1vh] pb-[0.5vh] text-[3vh] rounded-lg mx-[1vh] font-bold ${
          currentPage === 1
            ? "bg-[#ccc] opacity-50 cursor-not-allowed"
            : "bg-[#fff] text-black"
        }`}
      >
        <ArrowBackIcon />
      </button>

      {pageNumbers.map((page, index) =>
        typeof page === "number" ? (
          <button
            key={index}
            onClick={() => onPageChange(page)}
            className={`sidekick px-[2vh] py-[1vh] rounded-full mx-[1vh] ${
              page === currentPage
                ? "bg-[#fff333] text-black"
                : "bg-[#111] border-2 border-[#fff333] text-[#fff]"
            } `}
          >
            {page}
          </button>
        ) : (
          <span key={index} className="px-[2vh] py-[1vh] mx-[1vh] text-white">
            ...
          </span>
        )
      )}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-[1vh] pb-[0.5vh] text-[3vh] rounded-lg mx-[1vh] font-bold ${
          currentPage === totalPages
            ? "bg-[#ccc] opacity-50 cursor-not-allowed"
            : "bg-[#fff] text-black"
        }`}
      >
        <ArrowForwardIcon/>
      </button>
    </div>
  );
};

export default Pagination;
