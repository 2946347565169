import axios from "axios";
import { apiUrl } from "../utils/apiConfig";
import toast from "react-hot-toast";

export const uploadImage = async (file: File): Promise<string | null> => {
  const formData = new FormData();
  formData.append("imageBody", file);

  try {
    const response = await axios.post(
      `${apiUrl}/storage/upload-image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.success) {
      return response.data.data.storageURL;
    } else {
      toast.error("Failed to upload file.");
      return null;
    }
  } catch (error) {
    console.error("Error while uploading file", error);
    toast.error("Failed to upload file.");
    return null;
  }
};
