import React from "react";

interface LogoutCardProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const LogoutCard: React.FC<LogoutCardProps> = ({ isVisible, onClose, onConfirm }) => {
  if (!isVisible) return null;

  return (
    <div
      data-aos=""
      className="fixed z-50 inset-0 w-full h-full backdrop-blur-md bg-opacity-50 flex justify-center items-center transition-opacity duration-300 ease-in-out"
      onClick={onClose}
    >
      <div
        data-aos="zoom-in"
        data-aos-duration="800"
        className="relative bg-[#cfbe2914] lg:h-auto overflow-y-scroll p-[5vh] rounded-lg overflow-x-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <h1 className="text-[3vh] font-semibold text-white my-[1.5vh]">
          Are you sure you want to logout?
        </h1>
        <div className="flex flex-row justify-between items-center mt-[2vh]">
          <button
            className="border-2 border-[#fff333] hover:scale-110 transition-all duration-300 w-[46%] py-[1vh] text-white hover:text-[#111] hover:bg-[#fff333]"
            onClick={onConfirm}
          >
            <span className="text-[2.5vh] font-semibold">Yes</span>
          </button>
          <button
            className="bg-[#fff333] hover:scale-110 transition-all duration-300 w-[46%] py-[1vh] text-white hover:text-[#111] hover:bg-[#fff333]"
            onClick={onClose}
          >
            <span className="text-[2.5vh] text-[#111] font-semibold">No</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutCard;
