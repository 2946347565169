import React, { useState, useEffect } from "react";
import bunchuplogo from "../assets/nav_logo.webp";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AdminUserDetails from "../hooks/AdminUserDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import toast from "react-hot-toast";
import LogoutCard from "../components/LogoutCard";

const Navbar = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const [logoutCard, setLogoutCard] = useState(false);

  const handleToggleLogoutCard = () => {
    setLogoutCard(!logoutCard);
  };

  const navigate = useNavigate();

  const handlelogout = () => {
    Cookies.remove("access_token");
    toast.success("Logout Successful");
    navigate("/login");
  };

  const { user } = AdminUserDetails();

  return (
    <div className="top-0 z-10">
      <div className="shadow-md py-[1.5vh] bg-[#111] border-b-2 border-[#4f4f4f]">
        <div className="w-[95%] mx-auto flex flex-row justify-between items-center">
          <Link to="/">
            <img
              className="h-[6vh]"
              src={bunchuplogo}
              alt="Kirana-Friends-logo"
            />
          </Link>

          <div className="flex flex-row justify-center items-center gap-x-[2vh]">
            <Link to="/myprofile">
              <img
                src={user?.profilePic.imageUrl}
                alt="profile-pic"
                className="w-[6vh] hover:scale-110 h-[6vh] cursor-pointer rounded-full border-2 border-[#fff333] object-cover"
              />
            </Link>

            <button
              onClick={handleToggleLogoutCard}
              className="bg-[#ff3333] rounded-lg text-white flex flex-row gap-x-[1vh] items-center py-[1vh] px-[1.5vh]"
            >
              <LogoutIcon style={{ fontSize: 17 }} />
              <span className="text-[2vh] font-semibold text-white0">
                Logout
              </span>
            </button>
          </div>
        </div>
      </div>

      <LogoutCard
        isVisible={logoutCard}
        onClose={handleToggleLogoutCard}
        onConfirm={handlelogout}
      />

    </div>
  );
};

export default Navbar;
