/**
 * Formats an ISO date string to "16 August 2024, 06:24 PM"
 * @param isoDateString - The ISO 8601 date string
 * @returns The formatted date string
 */
export const formatDateTime = (isoDateString: string): string => {
    const date = new Date(isoDateString);
  
    // Create an options object to specify the desired format
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // for 12-hour clock format
    };
  
    // Format the date according to the options
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };
  