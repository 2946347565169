import axios from "axios";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { apiUrl } from "../utils/apiConfig";
import { encrypt, decrypt } from "../utils/encryptToken";

interface LoginData {
  telephone: string;
  password: string;
}

interface AuthResponse {
  token: string;
}

export const AuthLogin = async (
  loginData: LoginData
): Promise<AuthResponse | null> => {
  try {
    const response = await axios.post(`${apiUrl}/user-auth/login`, loginData);

    if (
      response.data &&
      response.data.data &&
      response.data.data.jwt &&
      response.data.data.jwt.token
    ) {
      const { token } = response.data.data.jwt;

      // Store the access token in cookies
      Cookies.set("access_token", token, { expires: 7 });
      toast.success("Login Successful");
      return { token };
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    toast.error("Invalid Credentials");
    return null;
  }
};

export const logout = () => {
  Cookies.remove("access_token");
  toast.success("Logged out successfully");
};

export const getAccessToken = (): string | undefined => {
  return Cookies.get("access_token");
};

export const ForgotPassword = async (
  email: string
): Promise<{ success: boolean }> => {
  try {
    const response = await axios.post(`${apiUrl}/user-auth/forgot-password`, {
      email,
    });
    if (response.data && response.data.success) {
      toast.success("Password reset link sent to your email");
      return { success: true };
    } else {
      const errorMessage = response.data.message || "Failed to send reset link";
      toast.error(errorMessage);
      return { success: false };
    }
  } catch (error) {
    let errorMessage = "Failed to send reset link";

    if (axios.isAxiosError(error)) {
      errorMessage = error.response?.data?.message || errorMessage;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.error("Failed to send reset link", error);
    toast.error(errorMessage);
    return { success: false };
  }
};

export const ResetPassword = async (
  resetToken: string,
  newPassword: string
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/user-auth/reset-password?token=${resetToken}`,
      {
        newPassword,
      }
    );

    if (response.data && response.data.success) {
      toast.success("Password reset successful");
    } else {
      const errorMessage = response.data.message || "Failed to reset password";
      toast.error(errorMessage);
    }
  } catch (error) {
    let errorMessage = "Failed to reset password";

    if (axios.isAxiosError(error)) {
      errorMessage = error.response?.data?.message || errorMessage;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.error("Failed to reset password", error);
    toast.error(errorMessage);
  }
};
