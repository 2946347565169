import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../utils/apiConfig";
import { College } from "../types/colleges";

interface CollegeListResponse {
  college: College[];
  totalResults: number;
  totalPages: number;
}

export const getCollegeList = async (
  page: number,
  limit: number,
  stateName?: string,
  districtName?: string,
  collegeName?: string
): Promise<CollegeListResponse | undefined> => {
  try {
    const response = await axios.get(`${apiUrl}/collage`, {
      params: {
        page,
        limit,
        stateName,
        districtName,
        collegeName,
      },
    });
    if (response.data.success) {
      return {
        college: response.data.data.results,
        totalResults: response.data.data.totalResults,
        totalPages: response.data.data.totalPages,
      };
    }
  } catch (error) {
    console.error("Error fetching college list:", error);
    throw new Error("Failed to fetch college list");
  }
};
