import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/Loading";
import { getOutingsListbyCategory } from "../../services/Outings";
import { Category } from "../../types/category";
import { getCategoryInterest } from "../../services/getCategoryInterest";
import { OutingData } from "../../types/outings";
import account from "../../assets/account.png";
import { formatDateTime } from "../../utils/dateTime";
import PlaceIcon from "@mui/icons-material/Place";

const EventsMainDashboard = () => {
  const navigate = useNavigate();

  const defaultPfpUrl =
    "https://d1rht7mb7jlq6e.cloudfront.net/assets/default-pfp.png";

  const [loading, setLoading] = useState(true);
  const [outings, setOutings] = useState<OutingData[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const data = await getCategoryInterest();
        setCategories(data.results);
        if (data.results.length > 0) {
          setSelectedCategory(data.results[2].id);
        }
      } catch (error) {
        console.error("Error fetching categories", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId: string) => {
    setSelectedCategory(categoryId);
  };

  useEffect(() => {
    const fetchOutings = async () => {
      if (selectedCategory) {
        setLoading(true);
        try {
          const userLatitude = 19.20663626259094;
          const userLongitude = 72.87454863429349;

          const data = await getOutingsListbyCategory(
            selectedCategory,
            userLatitude,
            userLongitude
          );
          if (data) {
            setOutings(data);
          }
          console.log("Outings Data by category", data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching Outings Data by category", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOutings();
  }, [selectedCategory]);

  const handleEventDetailClick = (title: string, id: string) => {
    const slug = title.toLowerCase().replace(/ /g, "-");
    navigate(`/events/${slug}/${id}`);
  };

  return (
    <div className="h-full  p-[2.5vh]">
      <div className="flex flex-row gap-x-[4vh]">
        {categories.map((category) => (
          <div
            key={category.id}
            className="w-[10vh] flex flex-col cursor-pointer hover:scale-105 transition-all duration-300 justify-center items-center"
            onClick={() => handleCategoryClick(category.id)}
          >
            <div
              className={`bg-[#2b2b2b9d] rounded-full p-[2vh] my-[1vh] ${
                selectedCategory === category.id
                  ? "border-2 border-[#fff333]"
                  : ""
              }`}
            >
              <img
                src={category.icon}
                className="w-[6vh] h-[6vh] object-cover"
              />
            </div>
            <p
              className={`${
                selectedCategory === category.id
                  ? "text-[#fff333]"
                  : "text-[#dadada]"
              } text-wrap text-center text-[2.1vh] font-semibold`}
            >
              {category.title}
            </p>
          </div>
        ))}
      </div>

      {loading ? (
        <div className="bg-[#111] h-[60vh] flex justify-center items-center">
          <div className="text-white text-[3vh]">Loading... </div>
          <div className="flex flex-row justify-center items-center">
            <div className="spinner"></div>
          </div>
        </div>
      ) : (
        <div className="mt-[4vh]">
          {outings.length > 0 ? (
            outings.map((outing) => (
              <div
                key={outing._id}
                className="bg-[#2b2b2b9d] relative my-[2vh] p-[2vh] rounded-lg"
              >
                <div className="absolute right-[2.5vh] top-[2.5vh] mb-[1vh]">
                  <p className="text-[3vh] font-semibold text-[#fff133d7]">
                    {formatDateTime(outing?.startDate)}
                  </p>
                </div>
                <div className="flex flex-row justify-between gap-x-[3vh]">
                  <div className="w-[15%] h-[20vh] bg-black flex flex-col justify-center items-center rounded-lg">
                    <img
                      src={outing?.displayPicture}
                      className="w-[100%] h-[15vh] object-cover"
                    />
                  </div>
                  <div className="w-[84%] py-[2vh]">
                    <h3 className="text-[#fff] font-semibold mb-[0.5vh] text-[3.5vh]">
                      {outing?.title}
                    </h3>
                    <div className="flex flex-row items-center gap-x-[1vh]">
                      <PlaceIcon
                        className="text-[#fff333]"
                        style={{ fontSize: 20 }}
                      />
                      <span className="text-[#fff333] font-semibold text-[1.9vh]">
                        {outing?.location?.name &&
                        outing.location.name.length > 40
                          ? `${outing.location.name.slice(0, 40)}...`
                          : outing?.location?.name}
                      </span>
                    </div>
                    <div className="my-[1.5vh] flex flex-row justify-start items-center">
                      {outing?.attendeeDetails &&
                      outing.attendeeDetails.length > 0 ? (
                        outing.attendeeDetails.map((attendee, index) => (
                          <img
                            key={index}
                            src={
                              attendee?.profilePic?.imageUrl === defaultPfpUrl
                                ? account
                                : attendee?.profilePic?.imageUrl
                            }
                            className="w-[5vh] h-[5vh] object-cover rounded-full"
                            alt="Attendee Profile"
                          />
                        ))
                      ) : (
                        <p className="text-[#bfbfbf] text-[2.1vh] mt-[1vh]">
                          No attendees available.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-[2.5vh] right-[2.5vh] flex flex-col justify-end items-end">
                  <button
                    className="bg-[#fff333] w-fit text-[#000] text-[2.1vh] rounded-lg px-[2vh] py-[0.5vh] font-semibold hover:scale-110 hover:text-[#000] transition-all duration-300"
                    onClick={() =>
                      handleEventDetailClick(outing.title, outing._id)
                    }
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div>
              {loading ? (
                <div className="bg-[#111] h-[60vh] flex justify-center items-center">
                  <div className="text-white text-[3vh]">Loading... </div>
                  <div className="flex flex-row justify-center items-center">
                    <div className="spinner"></div>
                  </div>
                </div>
              ) : (
                <div className="h-[60vh] flex flex-col justify-center items-center">
                  <p className="text-[#fa1b1b] text-[4vh] font-medium">
                    No outings available for this category😢.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventsMainDashboard;