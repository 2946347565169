import React, { useState, ChangeEvent, FormEvent } from "react";
import { ForgotPassword, ResetPassword } from "../../services/AuthService";
import toast from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { set } from "date-fns";

const ChangePasswordDashboard = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [isResetMode, setIsResetMode] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleForgotPassword = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await ForgotPassword(email);
      if (result.success) {
        setIsResetMode(true);
      }
    } catch (error) {
      console.error("Failed to send reset link", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (!newPassword || !confirmPassword || !token) {
      toast.error("All fields are required");
      setLoading(false);
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      setLoading(false);
      return;
    }
    try {
      await ResetPassword(token, newPassword);
      setConfirmPassword("");
      setNewPassword("");
      setToken("");
    } catch (error) {
      console.error("Error in reset password:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full p-[2.5vh]">
      <h1 className="text-[4vh] border-b-2 pb-[2vh] w-[50%] mx-auto border-[#3e3e3ebb] text-center font-semibold my-[2vh] text-[#dadada]">
        Forgot Password?
      </h1>

      <div className="w-[50%] mx-auto mt-[3vh]">
        {!isResetMode ? (
          <form
            onSubmit={handleForgotPassword}
            className="h-[50vh] flex flex-col justify-center"
          >
            <div className="my-[3vh]">
              <label className="text-[2.6vh] font-semibold text-[#d5d5d5]">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
                className="border focus:border-[#fff333] border-[#545454] focus:border-1 bg-transparent p-2 rounded-md w-full text-[2.5vh] text-white my-[1vh] focus:outline-none"
                required
              />
            </div>

            <button type="submit" className="w-full">
              <div className="submitbtn bg-[#fff333] w-full text-[2.9vh] text-center flex flex-col items-center justify-center h-[6vh] mx-auto px-[3vh] py-[1vh] rounded-full font-medium mb-[2vh]">
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <>Submit</>
                )}
              </div>
            </button>
          </form>
        ) : (
          <form action="" onSubmit={handleResetPassword}>
            <div className="my-[3vh]">
              <label className="text-[2.6vh] font-semibold text-[#d5d5d5]">
                Enter Token
              </label>
              <input
                type="text"
                name="token"
                value={token}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setToken(e.target.value);
                }}
                placeholder="Enter token received in email"
                className="border focus:border-[#fff333] border-[#545454] focus:border-1 bg-transparent p-2 rounded-md w-full text-[2.5vh] text-white my-[1vh] focus:outline-none"
                required
              />
            </div>

            <div className="relative my-[3vh]">
              <label className="text-[2.6vh] font-semibold text-[#d5d5d5]">
                Set New Password
              </label>
              <input
                type={passwordVisible ? "text" : "password"}
                name="newPassword"
                value={newPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewPassword(e.target.value)
                }
                placeholder="Enter new password"
                className="border focus:border-[#fff333] border-[#545454] focus:border-1 bg-transparent p-2 rounded-md w-full text-[2.5vh] text-white my-[1vh] focus:outline-none"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 top-[35%] right-0 flex items-center pr-3"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? (
                  <AiFillEyeInvisible size={23} color="#d5d5d5" />
                ) : (
                  <AiFillEye size={23} color="#d5d5d5" />
                )}
              </button>
            </div>

            <div className="relative my-[3vh]">
              <label className="text-[2.6vh] font-semibold text-[#d5d5d5]">
                Confirm New Password
              </label>
              <input
                type={passwordVisible ? "text" : "password"}
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
                placeholder="Enter password again"
                className="border focus:border-[#fff333] border-[#545454] focus:border-1 bg-transparent p-2 rounded-md w-full text-[2.5vh] text-white my-[1vh] focus:outline-none"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 top-[35%] right-0 flex items-center pr-3"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? (
                  <AiFillEyeInvisible size={23} color="#d5d5d5" />
                ) : (
                  <AiFillEye size={23} color="#d5d5d5" />
                )}
              </button>
            </div>

            <button type="submit" className="w-full">
              <div className="submitbtn bg-[#fff333] w-full text-[2.9vh] text-center flex flex-col items-center justify-center h-[6vh] mx-auto px-[3vh] py-[1vh] rounded-full font-medium mb-[2vh]">
                {loading ? (
                  <div className="flex flex-row justify-center items-center">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <>Submit</>
                )}
              </div>
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ChangePasswordDashboard;
