import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import account from "../../assets/account.png";
import { apiUrl } from "../../utils/apiConfig";
import qr from "../../assets/qr.svg";
import buncoins from "../../assets/buncoin.png";
import verified from "../../assets/tick.png";
import AOS from "aos";
import "aos/dist/aos.css";
import toast from "react-hot-toast";
import { formatDate } from "../../utils/dateUtil";
import { calculateAge } from "../../utils/age";
import { simplifyBuncoinsRepresentation } from "../../utils/formatCurrency";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/Loading";
import { getUserSidekickList } from "../../services/User";
import { getPublicProfileDetails } from "../../services/User";
import { Sidekick } from "../../types/sidekicks";

const UserDetails = () => {
  const navigate = useNavigate();

  const { username, id } = useParams<{ username: string; id: string }>();
  const [user, setUser] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [countdown, setCountdown] = useState<number>(5);

  const defaultPfpUrl =
    "https://d1rht7mb7jlq6e.cloudfront.net/assets/default-pfp.png";

  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showSidekickList, setShowSidekickList] = useState<boolean>(false);

  const [sidekicks, setSidekicks] = useState<Sidekick[]>([]);

  const handleProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  const handleSidekickList = () => {
    setShowSidekickList(!showSidekickList);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const userDetails = await getPublicProfileDetails(username!);
        setUser(userDetails);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("Error fetching user details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [username]);

  const friendId = id;
  console.log("Friends ID", friendId);

  useEffect(() => {
    if (friendId) {
      const loadSidekicks = async () => {
        try {
          const fetchedSidekicks = await getUserSidekickList(friendId);
          setSidekicks(fetchedSidekicks);
        } catch (err: any) {
          setError(err.message);
        }
      };

      loadSidekicks();
    }
  }, [friendId]);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  useEffect(() => {
    if (error) {
      const countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      const timer = setTimeout(() => {
        navigate("/users");
      }, 5000);

      return () => {
        clearInterval(countdownTimer);
        clearTimeout(timer);
      };
    }
  }, [error, navigate]);

  const handleUpdateProfile = () => {
    toast.success("Gaurav is working on this feature. Stay tuned!");
  };

  const handleViewSidekick = (username: string, id: string) => {
    navigate(`/user/${username}/${id}`);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <p className="text-[4vh] font-semibold text-[#dbdbdb]">
          User Not found
        </p>
        <div className="text-red-500 my-[2vh] text-[3vh]">{error}</div>
        <div className="text-[#fff333] text-[3vh]">
          Redirecting to users in {countdown} sec
        </div>
      </div>
    );
  }

  return (
    <div className="h-full p-[2.5vh]">
      <div className="flex flex-row h-[80vh] justify-start items-center gap-x-[2vh]">
        <div className="flex flex-col h-full w-[27%] items-center justify-center">
          <div onClick={handleProfileModal} className="relative">
            <img
              src={
                user.user?.profilePic.imageUrl === defaultPfpUrl
                  ? account
                  : user.user?.profilePic.imageUrl
              }
              alt="profile-pic"
              className="w-[25vh] h-[25vh] object-cover cursor-pointer rounded-full"
            />
            <div className="flex flex-row bg-[#474E68] border-2 border-[#4f4f4f] px-[1.5vh] py-[0.5vh] rounded-full justify-center items-center absolute bottom-[4vh] -right-[5vh]">
              <p className="sidekick text-[2.5vh] font-bold text-white">
                {simplifyBuncoinsRepresentation(user.userDetail?.coins ?? 0)}
              </p>
              <img src={buncoins} className="w-[4vh] h-[4vh]" alt="buncoins" />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center gap-x-[1vh] mt-[2vh]">
            <h1 className="text-[#fff] text-[3.2vh] font-semibold">
              {user.user?.fullName},
            </h1>
            {user.user?.dob && (
              <p className="text-[3.5vh] text-[#cdcdcd] font-semibold">
                {calculateAge(user.user.dob)}
              </p>
            )}

            {user.user?.profilePic.photoVerified && (
              <img
                src={verified}
                className="w-[3vh] h-[3vh]"
                alt="verified"
              />
            )}
            
          </div>
          <p className="text-[2.4vh] text-[#979797]">{user?.username}</p>

          <p className="text-[2.4vh] text-[#cdcdcd] mt-[2vh] font-semibold">
            DOB: {user.user?.dob && formatDate(user.user.dob)}
          </p>
          <p className="text-[2.4vh] text-[#cdcdcd] font-semibold">
            Gender: {user.user?.gender}
          </p>

          <div className="flex flex-row justify-center items-center">
            <button
              onClick={handleSidekickList}
              className="sidekick rounded-md mr-[1vh] bg-[#fff333] text-[#111] mt-[2vh] px-[2vh] py-[1vh] text-[2.4vh] font-medium"
            >
              Sidekicks: {user.userDetail?.totalFriends}
            </button>
          </div>
        </div>

        <div className="h-full w-[70%] p-[2vh]">
          <h1 className="text-[3vh] text-white mt-[3vh] font-semibold">
            Personal Details
          </h1>

          <div className="my-[4vh] flex flex-row justify-between">
            <div className="w-[48%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">Full Name</p>
              <input
                type="text"
                className="bg-transparent border-b-2  border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user.user?.fullName}
              />
            </div>
            <div className="w-[48%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">About Me</p>
              <input
                type="text"
                className="bg-transparent border-b-2 border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user.user?.bio}
              />
            </div>
          </div>

          <div className="my-[4vh] flex flex-row justify-between">
            <div className="w-[48%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">User Name</p>
              <input
                type="text"
                className="bg-transparent border-b-2  border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user.user?.username}
              />
            </div>
            <div className="w-[48%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">Date of Birth</p>
              <input
                type="text"
                className="bg-transparent border-b-2 border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user.user?.dob && formatDate(user.user.dob)}
              />
            </div>
          </div>

          <div className="my-[4vh] flex flex-row justify-between">
            <div className="w-[100%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">College</p>
              <textarea
                className="bg-transparent border-b-2 border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user.user?.college.name}
                rows={2}
                readOnly
              />
            </div>
          </div>

          {/* <button
            onClick={handleUpdateProfile}
            className="bg-[#fff333] py-[1vh] px-[2vh] hover:scale-110 transition-all duration-300"
          >
            <p className="text-[2.3vh] font-semibold">Update Details</p>
          </button> */}
        </div>

        {showProfileModal && (
          <div
            data-aos="zoom-in"
            className="fixed inset-0 w-full h-full backdrop-blur-md bg-opacity-50 flex justify-center items-center transition-opacity duration-300 ease-in-out"
            onClick={handleProfileModal}
          >
            <div
              data-aos="zoom-in"
              data-aos-duration="800"
              className="relative bg-[#cfbe2914] lg:h-auto overflow-y-scroll p-[5vh] rounded-lg overflow-x-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={handleProfileModal}
                className="absolute top-0 right-0 m-[2vh] bg-[#f00] px-[2vh] py-[1vh] rounded-lg hover:bg-red-700 transition-colors duration-300 ease-in-out"
              >
                <p className="text-[2.4vh] font-extrabold text-white">X</p>
              </button>
              <img
                src={
                  user.user?.profilePic.imageUrl === defaultPfpUrl
                    ? account
                    : user.user?.profilePic.imageUrl
                }
                className="w-[70vh] h-[70vh] mx-auto object-cover"
              />
            </div>
          </div>
        )}

        {showSidekickList && (
          <div
            data-aos="fade-left"
            className="fixed overflow-y-auto overflow-x-hidden inset-0 w-full h-full backdrop-blur-sm bg-opacity-50 flex justify-end items-center transition-opacity duration-300 ease-in-out"
            onClick={handleSidekickList}
          >
            <div
              data-aos="fade-left"
              data-aos-duration="800"
              className="relative bg-[#100e00] w-[30vw] lg:h-full overflow-y-scroll px-[5vh] rounded-lg overflow-x-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="sticky top-0 bg-[#100e00] pt-[3vh] pb-[3vh] z-10 border-b-2 border-[#4f4f4f]">
                <button
                  onClick={handleSidekickList}
                  className="absolute -top-[0vh] right-[0vh] m-[2vh] bg-[#f00] px-[2vh] py-[1vh] rounded-lg hover:bg-red-700 transition-colors duration-300 ease-in-out"
                >
                  <p className="text-[2.4vh] font-extrabold text-white">X</p>
                </button>
                <h1 className="sidekick text-[3vh] text-center text-[#bbbbbb] ">
                  <span className="font-bold">
                    {user.userDetail?.totalFriends}
                  </span>{" "}
                  Sidekicks
                </h1>
              </div>

              <div className="overflow-y-scroll overflow-x-hidden flex-1">
                {sidekicks.length === 0 ? (
                  <p className="text-center text-white font-semibold my-[3vh] text-[2.6vh]">
                    No sidekicks found.
                  </p>
                ) : (
                  sidekicks.map((sidekick) => (
                    <div
                      key={sidekick.username}
                      className="flex flex-row justify-between items-center my-[2vh]"
                    >
                      <div className="flex flex-row justify-start gap-x-[2.5vh] items-center">
                        <img
                          src={sidekick?.profilePic.imageUrl}
                          className="w-[8vh] rounded-full h-[8vh] object-cover"
                        />
                        <div>
                          <p className="text-[2.5vh] text-white font-semibold">
                            {sidekick?.fullName.length > 20
                              ? `${sidekick?.fullName.slice(0, 20)}...`
                              : sidekick?.fullName}
                          </p>
                          <p className="text-[2vh] text-[#979797]">
                            {sidekick?.username}
                          </p>
                        </div>
                      </div>

                      <button
                        onClick={() =>
                          handleViewSidekick(sidekick.username, sidekick.id)
                        }
                        className="text-[2.2vh] hover:scale-110 transition-all duration-300 font-semibold bg-[#fff333] rounded-full px-[2vh] py-[0.5vh]"
                      >
                        View
                      </button>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
