import React from "react";

const LoadingComponent = () => {
  return (
    <div className="bg-[#111] h-screen flex justify-center items-center">
      <div className="text-white text-[3vh]">Loading... </div>
      <div className="flex flex-row justify-center items-center">
        <div className="spinner"></div>
      </div>
    </div>
  );
};

export default LoadingComponent;
