import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import SchoolIcon from "@mui/icons-material/School";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CategoryIcon from "@mui/icons-material/Category";
import EventIcon from "@mui/icons-material/Event";
import LogoutIcon from "@mui/icons-material/Logout";
import forward from "../assets/arrow.png";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import bunchuplogo from "../assets/bunchuplogo.png";
import LogoutCard from "../components/LogoutCard";
import UploadIcon from "@mui/icons-material/Upload";
import InterestsIcon from "@mui/icons-material/Interests";
import ForumIcon from "@mui/icons-material/Forum";
import KeyIcon from "@mui/icons-material/Key";

type SidebarItem = {
  name: string;
  icon: keyof typeof iconMapping;
  link: string;
};

const iconMapping = {
  Dashboard: DashboardIcon,
  Users: PeopleIcon,
  Colleges: SchoolIcon,
  Category: InterestsIcon,
  Interest: FavoriteIcon,
  Events: EventIcon,
  Upload: UploadIcon,
  Discussions: ForumIcon,
  Password: KeyIcon,
} as const;

const sidebarComponents: SidebarItem[] = [
  // {
  //   name: "Dashboard",
  //   icon: "Dashboard",
  //   link: "/dashboard",
  // },
  {
    name: "Users",
    icon: "Users",
    link: "/users",
  },
  {
    name: "Colleges",
    icon: "Colleges",
    link: "/colleges",
  },
  {
    name: "Category/Interest",
    icon: "Category",
    link: "/category",
  },
  // {
  //   name: "Interest",
  //   icon: "Interest",
  //   link: "/interest",
  // },
  {
    name: "Events",
    icon: "Events",
    link: "/events",
  },
  {
    name: "Discussion",
    icon: "Discussions",
    link: "/discussions",
  },
  {
    name: "Upload",
    icon: "Upload",
    link: "/upload",
  },
  {
    name: "Change Password",
    icon: "Password",
    link: "/change-user-password",
  },
];

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const [logoutCard, setLogoutCard] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const handlelogout = () => {
    Cookies.remove("access_token");
    toast.success("Logout Successful");
    navigate("/login");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setOpen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize(); 
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const currentTab = sidebarComponents.find(
      (item) => item.link === location.pathname
    );
    if (currentTab) {
      setSelectedTab(currentTab.name);
    }
  }, [location.pathname]);

  const handleToggleLogoutCard = () => {
    setLogoutCard(!logoutCard);
  };

  return (
    <div>
      <div
        className={`${
          open ? "w-[18vw]" : "w-[7vh] lg:w-[12.5vh]"
        } shadow-md p-[1vh] lg:p-[3vh] h-screen flex border-r-2 border-[#4f4f4f] flex-col pt-[4vh] relative duration-300`}
      >
        <img
          src={forward}
          className={`absolute cursor-pointer w-[3.5vh] lg:w-[4vh] -right-[2vh] top-[6vh] border-1 rounded-full ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />

        <div className="flex flex-row items-center gap-x-[0vh] mb-[2vh]">
          <img src={bunchuplogo} className="w-[8vh] xl:w-[10vh]" />
          <h1
            className={`origin-left ml-[1vh] lg:text-[2.2vh] xl:text-[2.6vh] text-white font-semibold duration-200 ${
              !open && "scale-0 hidden"
            }`}
          >
            Admin Bunchup
          </h1>
        </div>

        {sidebarComponents.map((item, index) => {
          const Icon = iconMapping[item.icon];
          return (
            <div key={index}>
              <Link to={item.link} onClick={() => setSelectedTab(item.name)}>
                <div
                  className={`flex gap-x-[1vh] items-center my-[1vh] p-[1vh] rounded ${
                    selectedTab === item.name ? "bg-[#fff333]" : ""
                  }`}
                >
                  <Icon
                    style={{
                      color: selectedTab === item.name ? "#111" : "#fff",
                      fontSize: "3.2vh",
                    }}
                  />
                  <h1
                    className={`origin-left ml-[1vh] text-[2.1vh] xl:text-[2.6vh] font-semibold duration-200 ${
                      !open && "scale-0 hidden"
                    }`}
                    style={
                      selectedTab === item.name
                        ? { color: "#111" }
                        : { color: "#fff" }
                    }
                  >
                    {item.name}
                  </h1>
                </div>
              </Link>
            </div>
          );
        })}

        <button
          onClick={handleToggleLogoutCard}
          className={`flex gap-x-[1vh]  hover:bg-red-500 items-center my-[1vh] p-[1vh] rounded ${
            selectedTab === "Logout" ? "bg-red-500" : ""
          }`}
        >
          <LogoutIcon
            style={{
              color: "#fff",
              fontSize: "3.5vh",
            }}
          />
          <h1
            className={`origin-left ml-[1vh] text-[2.2vh] xl:text-[2.6vh] font-semibold duration-200 ${
              !open && "scale-0 hidden"
            }`}
            style={
              selectedTab === "Logout" ? { color: "#111" } : { color: "#fff" }
            }
          >
            Logout
          </h1>
        </button>

        <div
          className={`origin-left duration-200 ${
            !open && "scale-0 hidden"
          } mt-[2vh]  absolute bottom-10 font-medium`}
        >
          <p className="text-[1.9vh] xl:text-[2.2vh] text-[#bcbcbc]">
            Copyright@2023 Bunchup.in{" "}
          </p>
          <p className="text-center text-[2vh] xl:text-[2.4vh] mt-[0.5vh] font-medium text-[#8d8d8d]">
            Gaurav Madan❤️
          </p>
        </div>
      </div>

      <LogoutCard
        isVisible={logoutCard}
        onClose={handleToggleLogoutCard}
        onConfirm={handlelogout}
      />
    </div>
  );
};

export default Sidebar;
