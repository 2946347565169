import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";
import Sidebar from "./layout/Sidebar";
import MainDashboard from "./routes/maindashboard/MainDashboard";
import Login from "./routes/auth/Login";
import ProtectedRoute from "./context/ProtectedRoute";
import Colleges from "./routes/colleges/Colleges";
import CategoryDashboard from "./routes/category/CategoryDashboard";
import User from "./routes/user/UserDashboard";
import MyProfile from "./routes/user/myProfile";
import UserDetails from "./routes/user/userDetails";
import UploadStorage from "./routes/upload/UploadStorage";
import EventsMainDashboard from "./routes/events/EventsMainDashboard";
import EventsDetails from "./routes/events/EventsDetails";
import DiscussionDashboard from "./routes/discussions/DiscussionDashboard";
import ChangePasswordDashboard from "./routes/ChangePassword/ChangePasswordDashboard";
import { Toaster } from "react-hot-toast";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<ProtectedRoute element={<Login />} />} />

        <Route
          path="/*"
          element={
            <ProtectedRoute
              element={
                <>
                  <div className="flex flex-row w-[100%]">
                    <Sidebar />
                    <div className="h-screen overflow-y-scroll bg-[#111] w-full">
                      <Navbar />
                      <Routes>
                        <Route 
                          path="/" 
                          element={<Navigate to="/users" />} 
                        />
                        <Route 
                          path="/dashboard" 
                          element={<MainDashboard />} 
                        />
                        <Route 
                          path="/colleges" 
                          element={<Colleges />} 
                        />
                        <Route
                          path="/category"
                          element={<CategoryDashboard />}
                        />
                        <Route 
                          path="/users" 
                          element={<User />} 
                        />
                        <Route
                          path="/user/:username/:id"
                          element={<UserDetails />}
                        />
                        <Route 
                          path="/myprofile" 
                          element={<MyProfile />} 
                        />
                        <Route
                          path="/events"
                          element={<EventsMainDashboard />}
                        />
                        <Route
                          path="/events/:title/:id"
                          element={<EventsDetails />}
                        />
                        <Route 
                          path="*" 
                          element={<Navigate to="/" />} 
                        />
                        <Route 
                          path="/upload" 
                          element={<UploadStorage />} 
                        />
                        <Route 
                          path="/discussions" 
                          element={<DiscussionDashboard />}
                        />
                        <Route 
                          path="/change-user-password" 
                          element={<ChangePasswordDashboard />}
                        />  
                      </Routes>
                    </div>
                  </div>
                </>
              }
            />
          }
        />
      </Routes>

      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
          style: {
            background: "#fff",
            color: "#111",
            borderRadius: "none",
            padding: "2vh",
            fontSize: "2vh",
          },
          success: {
            style: {
              background: "#fff",
              color: "#111",
              borderRadius: "none",
              fontWeight: "bold",
              padding: "2vh",
              fontSize: "2vh",
            },
          },
          error: {
            style: {
              background: "#fff",
              color: "#111",
              borderRadius: "none",
              fontWeight: "bold",
              padding: "2vh",
              fontSize: "2vh",
            },
          },
        }}
      />
    </BrowserRouter>
  );
}

export default App;
