import React, { useEffect, useState } from "react";
import LoadingComponent from "../../components/Loading";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";
import { apiUrl } from "../../utils/apiConfig";
import toast from "react-hot-toast";
import { uploadImage } from "../../services/uploadImage";

const UploadStorage = () => {
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }
    setLoading(true);

    const url = await uploadImage(selectedFile);
    setUploadedUrl(url);
    setLoading(false);
  };

  const handleCopyToClipboard = () => {
    if (uploadedUrl) {
      navigator.clipboard.writeText(uploadedUrl);
      toast.success("Copied to clipboard!");
    } else {
      toast.error("No URL to copy");
    }
  };

  return (
    <div className="h-full p-[2.5vh] flex flex-col items-center">
      <h1 className="text-[4vh] text-center font-semibold my-[2vh] text-[#dadada]">
        Convert Image into URL's
      </h1>

      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          id="file-upload"
        />

        {/* Custom Button Styled as Label */}

        <div className="border-2 border-[#5b5b5b] rounded-2xl p-[1vh] w-[40vw] flex flex-row items-center">
          <label htmlFor="file-upload" className="custom-file-upload">
            Browse
          </label>

          {selectedFile ? (
            <p className="text-[#fff333] text-[2.5vh] ml-[2vh]">
              {selectedFile.name}
            </p>
          ) : (
            <p className="text-[#fff333] text-[2.5vh] ml-[2vh]">
              No file selected
            </p>
          )}
        </div>

        {/* Image Preview */}
        <div className="h-[40vh] flex flex-col items-center justify-center">
          {selectedFile ? (
            <img
              src={URL.createObjectURL(selectedFile)}
              className="h-[35vh]  object-contain my-[2vh] shadow-md"
              alt="Selected"
            />
          ) : (
            <h1 className="text-[3vh] text-center text-white font-medium">
              No Image Selected
            </h1>
          )}
        </div>

        <div className="">
          <button
            type="submit"
            className="submitbtn bg-[#fff333] w-[30vh] text-[2.9vh] text-center flex flex-col items-center justify-center h-[6vh] mx-auto px-[3vh] py-[1vh] rounded-full font-medium mb-[2vh]"
          >
            {loading ? <div className="spinner"></div> : <div>Convert</div>}
          </button>
        </div>
      </form>

      <h1 className="text-[3vh] text-center text-white font-medium">
        Your Converted URL
      </h1>
      <div className="my-[3vh] bg-transparent border-2 focus:outline-none p-[1vh] border-[#fff333] text-[3vh] w-[70%] rounded-md flex flex-row justify-between items-center">
        <input
          type="text"
          className="w-full bg-transparent focus:outline-none text-[2.5vh] text-white"
          placeholder="Your URL here"
          value={uploadedUrl || ""}
          readOnly
        />
        <ContentCopyIcon
          style={{ fontSize: 30 }}
          className="cursor-pointer text-[#fff333] mr-[1vh]"
          onClick={handleCopyToClipboard}
        />
      </div>
    </div>
  );
};

export default UploadStorage;
