import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AdminUserDetails from "../../hooks/AdminUserDetails";
import qr from "../../assets/qr.svg";
import buncoins from "../../assets/buncoin.png";
import verified from "../../assets/tick.png";
import AOS from "aos";
import "aos/dist/aos.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/dateUtil";
import { calculateAge } from "../../utils/age";
import LoadingComponent from "../../components/Loading";
import { simplifyBuncoinsRepresentation } from "../../utils/formatCurrency";
import { getUserSidekickList } from "../../services/User";
import { Sidekick } from "../../types/sidekicks";

const MyProfile = () => {
  const navigate = useNavigate();

  const { user, userDetail, loading } = AdminUserDetails();
  const [error, setError] = useState<string | null>(null);
  const [showQRModal, setShowQRModal] = useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showSidekickList, setShowSidekickList] = useState<boolean>(false);

  const [sidekicks, setSidekicks] = useState<Sidekick[]>([]);
  const userId = user?.id;
  console.log("UserId", userId);

  useEffect(() => {
    if (userId) {
      const loadSidekicks = async () => {
        try {
          const fetchedSidekicks = await getUserSidekickList(userId);
          setSidekicks(fetchedSidekicks);
          console.log("Sidekicks", fetchedSidekicks);
        } catch (err: any) {
          setError(err.message);
        }
      };

      loadSidekicks();
    }
  }, [userId]);

  const handleQRModal = () => {
    setShowQRModal(!showQRModal);
  };

  const handleProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  const handleSidekickList = () => {
    setShowSidekickList(!showSidekickList);
  };

  const handleProfileUpdate = () => {
    toast.success("Gaurav is working on this feature. Stay tuned!");
  };

  const handleViewSidekick = (username: string, id: string) => {
    navigate(`/user/${username}/${id}`);
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="h-full p-[2.5vh]">
      <div className="flex flex-row justify-start items-center gap-x-[2vh]">
        <div className="flex flex-col h-full w-[27%] items-center justify-center">
          <div onClick={handleProfileModal} className="relative">
            <img
              src={user?.profilePic.imageUrl}
              alt="profile-pic"
              className="w-[25vh] h-[25vh] object-cover cursor-pointer rounded-full"
            />
            <div className="flex flex-row bg-[#474E68] border-2 border-[#4f4f4f] px-[1.5vh] py-[0.5vh] rounded-full justify-center items-center absolute bottom-[4vh] -right-[5vh]">
              <p className="sidekick text-[2.5vh] font-bold text-white">
                {simplifyBuncoinsRepresentation(userDetail?.coins ?? 0)}
              </p>
              <img src={buncoins} className="w-[4vh] h-[4vh]" alt="buncoins" />
            </div>
          </div>
          <div className="flex flex-row justify-center gap-x-[1vh] items-center  mt-[2vh]">
            <h1 className="text-[#fff] text-[3.2vh] font-semibold">
              {user?.fullName},
            </h1>
            {user?.dob && (
              <p className="text-[3.5vh] text-[#cdcdcd] font-semibold">
                {calculateAge(user.dob)}
              </p>
            )}

            {user?.profileVerified && (
              <img src={verified} className="w-[3vh] h-[3vh]" alt="verified" />
            )}
          </div>
          <p className="text-[2.4vh] text-[#979797]">{user?.username}</p>

          <p className="text-[2.4vh] text-[#cdcdcd] mt-[2vh] font-semibold">
            DOB: {user?.dob && formatDate(user.dob)}
          </p>
          <p className="text-[2.4vh] text-[#cdcdcd] font-semibold">
            Gender: {user?.gender}
          </p>

          <div className="flex flex-row justify-center items-center">
            <button
              onClick={handleSidekickList}
              className="sidekick rounded-md mr-[1vh] bg-[#fff333] text-[#111] mt-[2vh] px-[2vh] py-[1vh] text-[2.4vh] font-medium"
            >
              Sidekicks: {userDetail?.totalFriends}
            </button>

            <button
              onClick={handleQRModal}
              className="sidekick rounded-md bg-[#fff] text-[#111] mt-[2vh]  py-[0.4vh] text-[2.4vh] font-medium"
            >
              <img src={qr} className="w-[4.5vh] h-[4.5vh]" />
            </button>
          </div>
        </div>

        <div className="h-full w-[70%] p-[2vh]">
          <h1 className="text-[3vh] text-white mt-[3vh] font-semibold">
            Personal Details
          </h1>

          <div className="my-[4vh] flex flex-row justify-between">
            <div className="w-[48%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">Full Name</p>
              <input
                type="text"
                className="bg-transparent border-b-2  border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user?.fullName}
              />
            </div>
            <div className="w-[48%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">About Me</p>
              <input
                type="text"
                className="bg-transparent border-b-2 border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user?.bio}
              />
            </div>
          </div>

          <div className="my-[4vh] flex flex-row justify-between">
            <div className="w-[48%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">User Name</p>
              <input
                type="text"
                className="bg-transparent border-b-2  border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user?.username}
              />
            </div>
            <div className="w-[48%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">Date of Birth</p>
              <input
                type="text"
                className="bg-transparent border-b-2 border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user?.dob && formatDate(user.dob)}
              />
            </div>
          </div>

          <div className="my-[4vh] flex flex-row justify-between">
            <div className="w-[100%]">
              <p className="text-[#a2a1a1] text-[2.4vh]">College</p>
              <textarea
                className="bg-transparent border-b-2 border-[#fff333] text-[#fff] w-[100%] py-[1vh] px-[1vh] text-[2.4vh] focus:outline-none"
                value={user?.college.name}
                rows={2}
                readOnly
              />
            </div>
          </div>

          <button
            onClick={handleProfileUpdate}
            className="bg-[#fff333] py-[1vh] px-[2vh] hover:scale-110 transition-all duration-300"
          >
            <p className="text-[2.3vh] font-semibold">Update Details</p>
          </button>
        </div>

        {showQRModal && (
          <div
            data-aos="zoom-in"
            className="fixed inset-0 w-full h-full backdrop-blur-md bg-opacity-50 flex justify-center items-center transition-opacity duration-300 ease-in-out"
            onClick={handleQRModal} 
          >
            <div
              data-aos="zoom-in"
              data-aos-duration="800"
              className="relative bg-[#cfbe2914] lg:h-auto overflow-y-scroll p-[5vh] rounded-lg overflow-x-auto"
              onClick={(e) => e.stopPropagation()} 
            >
              <button
                onClick={handleQRModal}
                className="absolute top-0 right-0 m-[2vh] bg-[#f00] px-[2vh] py-[1vh] rounded-lg hover:bg-red-700 transition-colors duration-300 ease-in-out"
              >
                <p className="text-[2.4vh] font-extrabold text-white">X</p>
              </button>
              <img src={user?.qrCode} className="w-[50vh] h-[50vh] mx-auto" />
            </div>
          </div>
        )}
      </div>

      <h1 className="text-[3.2vh] w-[90%] font-medium mx-auto mt-[4vh] text-[#fff133d6]">
        My Interest
      </h1>
      <div className="flex flex-row flex-wrap w-[90%] mx-auto my-[2vh] gap-[1vh]">
        {user?.interests.map((interest) => (
          <div
            key={interest.id}
            className="border-2 hover:scale-110 hover:border-[#fff333] hover:bg-[#fff333]  cursor-pointer transition-all text-[#aaaaaab3] hover:text-[#111] duration-300 border-[#aaaaaab3] px-[1vh] py-[0.5vh] rounded-full"
          >
            <p className=" font-medium text-[2.1vh]">{interest.name}</p>
          </div>
        ))}
      </div>

      {showProfileModal && (
        <div
          data-aos="zoom-in"
          className="fixed inset-0 w-full h-full backdrop-blur-md bg-opacity-50 flex justify-center items-center transition-opacity duration-300 ease-in-out"
          onClick={handleProfileModal} // Close the modal when clicking outside
        >
          <div
            data-aos="zoom-in"
            data-aos-duration="800"
            className="relative bg-[#cfbe2914] lg:h-auto overflow-y-scroll p-[5vh] rounded-lg overflow-x-auto"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <button
              onClick={handleProfileModal}
              className="absolute top-0 right-0 m-[2vh] bg-[#f00] px-[2vh] py-[1vh] rounded-lg hover:bg-red-700 transition-colors duration-300 ease-in-out"
            >
              <p className="text-[2.4vh] font-extrabold text-white">X</p>
            </button>
            <img
              src={user?.profilePic.imageUrl}
              className="w-[70vh] h-[70vh] mx-auto object-cover"
            />
          </div>
        </div>
      )}

      {showSidekickList && (
        <div
          data-aos="fade-left"
          className="fixed overflow-y-auto overflow-x-hidden inset-0 w-full h-full backdrop-blur-sm bg-opacity-50 flex justify-end items-center transition-opacity duration-300 ease-in-out"
          onClick={handleSidekickList}
        >
          <div
            data-aos="fade-left"
            data-aos-duration="800"
            className="relative bg-[#100e00] w-[30vw] lg:h-full overflow-y-scroll px-[5vh] rounded-lg overflow-x-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="sticky top-0 bg-[#100e00] pt-[3vh] pb-[3vh] z-10 border-b-2 border-[#4f4f4f]">
              <button
                onClick={handleSidekickList}
                className="absolute -top-[0vh] right-[0vh] m-[2vh] bg-[#f00] px-[2vh] py-[1vh] rounded-lg hover:bg-red-700 transition-colors duration-300 ease-in-out"
              >
                <p className="text-[2.4vh] font-extrabold text-white">X</p>
              </button>
              <h1 className="sidekick text-[3vh] text-center text-[#bbbbbb] ">
                <span className="font-bold">{userDetail?.totalFriends}</span>{" "}
                Sidekicks
              </h1>
            </div>

            <div className="overflow-y-scroll overflow-x-hidden flex-1">
              {sidekicks.length === 0 ? (
                <p className="text-center text-white my-[3vh] font-semibold text-[2.6vh]">
                  No sidekicks found.
                </p>
              ) : (
                sidekicks.map((sidekick) => (
                  <div className="">
                    <div
                      key={sidekick.username}
                      className="flex flex-row justify-between items-center my-[2vh]"
                    >
                      <div className="flex flex-row justify-start gap-x-[2.5vh] items-center">
                        <img
                          src={sidekick?.profilePic.imageUrl}
                          className="w-[8vh] rounded-full h-[8vh] object-cover"
                        />
                        <div>
                          <p className="text-[2.5vh] text-white font-semibold">
                            {sidekick?.fullName.length > 20
                              ? `${sidekick?.fullName.slice(0, 20)}...`
                              : sidekick?.fullName}
                          </p>
                          <p className="text-[2.1vh] text-[#979797]">
                            {sidekick?.username}
                          </p>
                        </div>
                      </div>

                      <button
                        onClick={() =>
                          handleViewSidekick(sidekick.username, sidekick.id)
                        }
                        className="text-[2.2vh] hover:scale-110 transition-all duration-300 font-semibold bg-[#fff333] rounded-full px-[2vh] py-[0.5vh]"
                      >
                        View
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
