import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { fetchAllUsersDetails } from "../../services/User";
import { User } from "../../types/user";
import account from "../../assets/account.png";
import Pagination from "../../components/Pagination";
import { apiUrl } from "../../utils/apiConfig";
import LoadingComponent from "../../components/Loading";
import Search from "../../assets/search.png";
import debounce from "lodash/debounce";

const UserDashboard = () => {
  const navigate = useNavigate();

  const defaultPfpUrl =
    "https://d1rht7mb7jlq6e.cloudfront.net/assets/default-pfp.png";

  const [users, setUsers] = useState<User[]>([]);
  const [totalUsers, setTotalUsers] = useState<number | null>(null);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("fullName");
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null
  );
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getUsersData = async (search = "") => {
    setLoading(true);
    try {
      const { users, totalUsers, totalPages } = await fetchAllUsersDetails(
        currentPage,
        limit,
        search,
        isSearchActive ? sortBy : ""
      );
      setUsers(users);
      setTotalUsers(totalUsers);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      getUsersData(query);
    }, 500),
    []
  );

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    setIsSearchActive(query.trim().length > 0);
    debouncedSearch(query);
  };

  useEffect(() => {
    getUsersData(isSearchActive ? searchQuery : "");
  }, [currentPage, limit, sortBy, isSearchActive]);

  const handleViewClick = (username: string, id: string) => {
    navigate(`/user/${username}/${id}`);
  };

  const handlePageChange = (page: number) => {
    if (totalPages !== null && page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearch = async () => {
    if (searchQuery.trim()) {
      navigate(`/user/${searchQuery}`);
    }
  };

  return (
    <div className="h-[120vh] p-[2.5vh]">
      <div className="flex flex-row mb-[3vh] justify-between items-center">
        <h1 className="text-[2.8vh] font-semibold text-white text-left">
          This is User Dashboard
        </h1>

        <div className="flex flex-row justify-center items-center  border-2 border-[#787878]">
          <input
            type="text"
            className="bg-transparent focus:outline-none text-white px-[1.5vh] py-[0.5vh] w-[40vh] text-[2.1vh] placeholder-[#fff] placeholder-opacity-50"
            placeholder="Search User by Name"
            onChange={handleSearchInputChange}
          />
          <div onClick={handleSearch} className="bg-[#fff333] p-[0.5vh]">
            <img
              src={Search}
              className="w-[3.4vh] h-[3.5vh] overflow-hidden hover:scale-110 transition-all duration-300 cursor-pointer"
            />
          </div>
        </div>

        <h1 className="sidekick text-white text-[2.8vh] font-semibold">
          Total Users: {totalUsers}
        </h1>
      </div>

      {loading ? (
        <>
          <LoadingComponent />
        </>
      ) : (
        <table className="p-[1vh] border-2 border-[#4f4f4f] w-[100%] mb-[2vh]">
          <thead>
            <tr className="bg-[#fff333] text-black">
              <th className="text-[2.2vh] p-[1.5vh] border-2 border-[#4f4f4f] text-start">
                Sr No
              </th>
              <th className="text-[2.2vh] p-[1.5vh] border-2 border-[#4f4f4f] text-start">
                Profile Img
              </th>
              <th className="text-[2.2vh] p-[1.5vh] hidden sm:table-cell lg:table-cell border-2 border-[#4f4f4f] text-start">
                User Name
              </th>
              <th className="text-[2.2vh] p-[1.5vh] hidden sm:table-cell lg:table-cell border-2 border-[#4f4f4f] text-start">
                Full Name
              </th>
              <th className="hidden text-[2.2vh lg:table-cell border-2 p-[1.5vh] border-[#4f4f4f] text-start">
                Mobile Number
              </th>
              <th className="text-[2.2vh] border-2 p-[1.5vh] border-[#4f4f4f] text-start">
                College
              </th>
              <th className="text-[2.2vh] border-2 p-[1.5vh] border-[#4f4f4f] text-start">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {users.map((user, index) => (
              <tr key={index} className="border-2">
                <td className="sidekick text-center text-[2.1vh] text-white border-2 border-[#4f4f4f]">
                  {index + currentPage * limit - limit + 1}
                </td>

                <td className="text-start text-white px-[2vh] border-2 border-[#4f4f4f]">
                  <img
                    src={
                      user?.profilePic?.imageUrl === defaultPfpUrl
                        ? account
                        : user?.profilePic?.imageUrl || account
                    }
                    alt="profile-pic"
                    className="w-[6vh] h-[6vh] rounded-full object-cover"
                  />
                </td>

                <td className="text-start text-[2.5vh] text-white hidden sm:table-cell lg:table-cell px-[2vh] border-2 border-[#4f4f4f]">
                  {user?.username}
                </td>

                <td className="text-start text-[2.5vh] text-white hidden sm:table-cell lg:table-cell px-[2vh] border-2 border-[#4f4f4f]">
                  {user?.fullName}
                </td>

                <td className="sidekick text-[2.3vh] text-start text-white hidden lg:table-cell px-[2vh] border-2 border-[#4f4f4f]">
                  {user?.telephone}
                </td>

                <td className="text-start text-[2.5vh] text-white px-[2vh] border-2 border-[#4f4f4f] py-[1.5vh]">
                  {user?.college.name.slice(0, 30)}
                </td>

                <td className=" text-center text-white  border-2 border-[#4f4f4f] py-[2vh] ">
                  <button
                    onClick={() => handleViewClick(user.username, user.id)}
                    className=" px-[1vh]  lg:py-[0.5vh] lg:px-[3vh] backdrop-blur-lg text-[#fff333] border-2 border-[#fff333] cursor-pointer hover:scale-125 transition-all"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages || 1}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default UserDashboard;
