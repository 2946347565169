import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/Loading";
import {
  getOutingDetailsByID,
  getOutingAttendeesDetails,
  getOutingsRequestDetails,
} from "../../services/Outings";
import { OutingData, Attendee, Request } from "../../types/outings";
import { formatDate } from "../../utils/dateUtil";
import PlaceIcon from "@mui/icons-material/Place";

const EventsDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [outingDetails, setOutingDetails] = useState<OutingData[]>([]);
  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [requests, setRequests] = useState<Request[]>([]);
  const [showAttendees, setShowAttendees] = useState(false);
  const [showRequests, setShowRequests] = useState(false);
  const { id } = useParams();

  const handleShowAttendeees = () => {
    setShowAttendees(!showAttendees);
  };

  const handleShowRequests = () => {
    setShowRequests(!showRequests);
  };

  useEffect(() => {
    const fetchOutingsDetails = async () => {
      setLoading(true);
      try {
        const data = await getOutingDetailsByID(id!);
        setOutingDetails(data);
      } catch (error) {
        console.error("Error fetching outing details", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchOutingAttendees = async () => {
      try {
        const attendeedata = await getOutingAttendeesDetails(id!);
        console.log("Outing Attendees Fetched", attendeedata);
        setAttendees(attendeedata);
      } catch (error) {
        console.error("Error fetching outing attendees", error);
      }
    };

    const fetchOutingRequests = async () => {
      try {
        const requestsData = await getOutingsRequestDetails(id!);
        console.log("Outing Requests Fetched", requestsData);
        setRequests(requestsData);
      } catch (error) {
        console.error("Error fetching outing requests", error);
      }
    };

    if (id) {
      fetchOutingsDetails();
      fetchOutingAttendees();
      fetchOutingRequests();
    }
  }, [id]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (outingDetails.length === 0) {
    return <div>Error: No outing details found.</div>;
  }

  const outing = outingDetails[0];

  const handleViewHost = (username: string, id: string) => {
    navigate(`/user/${username}/${id}`);
  };

  return (
    <div className="h-auto p-6">
      <div className="flex flex-row justify-between ">
        {/* Event Image Details */}
        <div className="w-[48%] border-r-2 border-[#726c1363] pr-[3vh]">
          <img
            src={outing?.displayPicture}
            className="w-[90%] h-[30vh] object-cover border-2 border-[#151515]"
          />
          <p className="text-[3.5vh] my-[1.5vh] font-semibold text-[#dedede]">
            {outing?.title}
          </p>
          <p className="text-[2.4vh] italic text-[#fff1339c] font-normal">
            {outing?.description}
          </p>

          <div className="my-[2vh]">
            <p className="text-[2.4vh] text-[#ffffffb3] my-[1vh]">
              <span className="font-semibold">Start Date: &nbsp;&nbsp;</span>{" "}
              {formatDate(outing?.startDate)} {"  at  "} {outing?.startTime}
            </p>
            <p className="text-[2.4vh] text-[#ffffffb3]">
              <span className="font-semibold">End Date:&nbsp;&nbsp; </span>{" "}
              {formatDate(outing?.endDate)} {"  at  "} {outing?.endTime}
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-[1vh]">
            <PlaceIcon style={{ fontSize: 40, color: "#fff333" }} />
            <div>
              <p className="text-[2.2vh] text-[#fff333] font-semibold">
                {outing?.location.name}
              </p>
              <p className="text-[1.9vh] text-[#d1d1d1] font-normal">
                {outing?.location?.address.slice(0, 70)}...
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-x-[2vh]">
            <button
              onClick={handleShowAttendeees}
              className="text-[2.4vh] my-[3vh] bg-[#fff333] font-bold px-[1.5vh] py-[1vh] hover:scale-110 transition-all duration-300"
            >
              Attendees:&nbsp;{outing?.attendeesCount}
            </button>

            <button
              onClick={handleShowRequests}
              className="text-[2.4vh] my-[3vh] text-[#ffffff94] border-2 border-[#ffffff94] font-bold px-[1.5vh] py-[1vh] hover:scale-110 transition-all duration-300"
            >
              View Requests
            </button>
          </div>
        </div>

        {/* Event Guidelines */}
        <div className="w-[48%] pr-[2vh]">
          <div className="flex flex-row items-center justify-end gap-x-5">
            <p className="text-[3.2vh] text-white font-semibold">
              Event Hosted by
            </p>
            <div className="flex flex-row items-center gap-x-[1vh]">
              <img
                src={outing?.hostDetails?.profilePic?.imageUrl}
                className="w-[7vh] h-[7vh] hover:scale-125 transition-all duration-300 cursor-pointer object-cover rounded-full"
                alt="host-image"
                onClick={() =>
                  handleViewHost(
                    outing?.hostDetails.username,
                    outing?.hostDetails._id
                  )
                }
              />
              <div>
                <p className="text-[2.4vh] font-semibold text-[#fff333]">
                  {outing?.hostDetails.fullName}
                </p>
                <p className="text-[1.9vh] text-[#d1d1d1]">
                  @{outing?.hostDetails.username}
                </p>
              </div>
            </div>
          </div>

          {/* Rules And Guidelines */}
          <div className=" my-[6vh]">
            <p className="text-[3vh] text-[#c2a514]">Rules/Event Guidelines</p>
            {outing?.rules.length === 0 && (
              <p className="text-[2.4vh] my-[3vh] italic text-[#fff] font-normal">
                No Rules Available
              </p>
            )}
            {outing?.rules.map((rule, index) => (
              <div
                key={index}
                className="flex flex-row gap-x-2 items-center my-[2vh]"
              >
                <li className="text-[2.9vh] list-none text-[#efefef] font-normal">
                  {index + 1}.
                </li>
                <p className="monster text-[2.3vh] text-[#efefef] font-normal">
                  {rule}
                </p>
              </div>
            ))}
          </div>

          <div>
            {attendees?.length > 0 ? (
              <div>
                <p className="text-[3vh] text-[#c2a514] font-medium">
                  See who is Attending
                </p>
                <div className="flex flex-row items-center my-[3vh]">
                  {attendees.slice(0, 5).map((attendee, index) => (
                    <div
                      key={attendee?.id}
                      className={`flex flex-row ${
                        index > 0 ? "-ml-[2vh]" : "0"
                      }`}
                    >
                      <img
                        src={attendee?.profilePic?.imageUrl}
                        className="w-[7vh] h-[7vh]  object-cover rounded-full"
                      />
                    </div>
                  ))}
                  {attendees.length > 5 && (
                    <div className="ml-[1vh] flex items-center justify-center text-[2.4vh] text-[#fff] font-semibold">
                      +{attendees.length - 5} more
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <p className="text-[2.4vh] text-[#fff] font-semibold">
                No one is Attending Yet
              </p>
            )}
          </div>
        </div>
      </div>

      {showAttendees && (
        <div
          data-aos="fade-left"
          className="fixed overflow-y-auto overflow-x-hidden inset-0 w-full h-full backdrop-blur-sm bg-opacity-50 flex justify-end items-center transition-opacity duration-300 ease-in-out"
          onClick={handleShowAttendeees}
        >
          <div
            data-aos="fade-left"
            data-aos-duration="800"
            className="relative bg-[#100e00] w-[30vw] lg:h-full overflow-y-scroll px-[5vh] rounded-lg overflow-x-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="sticky top-0 bg-[#100e00] pt-[3vh] pb-[3vh] z-10 border-b-2 border-[#4f4f4f]">
              <button
                onClick={handleShowAttendeees}
                className="absolute -top-[0vh] right-[0vh] m-[2vh] bg-[#f00] px-[2vh] py-[1vh] rounded-lg hover:bg-red-700 transition-colors duration-300 ease-in-out"
              >
                <p className="text-[2.4vh] font-extrabold text-white">X</p>
              </button>
              <h1 className="sidekick text-[3vh] text-center text-[#bbbbbb] ">
                <span className="font-bold"></span> View Attendees
              </h1>
            </div>
            <div className="">
              {attendees.length === 0 && (
                <p className="text-[2.4vh] text-[#fff] text-center my-[3vh] font-semibold">
                  No one is Attending Yet
                </p>
              )}
              <div className="grid grid-cols-4 my-[2.5vh] flex-wrap items-center justify-center">
                {attendees.map((attendee, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center gap-x-2 my-[2vh]"
                  >
                    <img
                      src={attendee?.profilePic?.imageUrl}
                      className="w-[9vh] h-[9vh] hover:scale-125 transition-all duration-300 object-cover rounded-full"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {showRequests && (
        <div
          data-aos="fade-left"
          className="fixed overflow-y-auto overflow-x-hidden inset-0 w-full h-full backdrop-blur-sm bg-opacity-50 flex justify-end items-center transition-opacity duration-300 ease-in-out"
          onClick={handleShowRequests}
        >
          <div
            data-aos="fade-left"
            data-aos-duration="800"
            className="relative bg-[#100e00] w-[30vw] lg:h-full overflow-y-scroll px-[5vh] rounded-lg overflow-x-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="sticky top-0 bg-[#100e00] pt-[3vh] pb-[3vh] z-10 border-b-2 border-[#4f4f4f]">
              <button
                onClick={handleShowRequests}
                className="absolute -top-[0vh] right-[0vh] m-[2vh] bg-[#f00] px-[2vh] py-[1vh] rounded-lg hover:bg-red-700 transition-colors duration-300 ease-in-out"
              >
                <p className="text-[2.4vh] font-extrabold text-white">X</p>
              </button>
              <h1 className="sidekick text-[3vh] text-center text-[#bbbbbb] ">
                <span className="font-bold"></span> Requested Attendees
              </h1>
            </div>
            <div className="my-[4vh]">
              {requests.length === 0 && (
                <p className="text-[2.4vh] text-[#fff] font-semibold">
                  No one is Requested Yet
                </p>
              )}
              {requests.map((request, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-between gap-x-2 my-[3vh]"
                >
                  <img
                    src={request?.userId?.profilePic?.imageUrl}
                    className="w-[9vh] h-[9vh] rounded-full object-cover"
                  />
                  <div className="w-[80%]">
                    <p className="text-[2.4vh] text-[#fff] font-semibold">
                      {request?.userId?.fullName}
                    </p>
                    <p className="italic text-wrap font-medium text-[2vh] text-[#bababa]">
                      {request?.message}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventsDetails;
