import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { College } from "../../types/colleges";
import { getCollegeList } from "../../services/getCollegeList";

const Colleges = () => {
  const navigate = useNavigate();
  const [college, setCollege] = useState<College[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [totalResults, setTotalResults] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [districtName, setDistrictName] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(searchTerm);
  const limit = 10;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const getCollegeData = async () => {
      setLoading(true);
      try {
        const data = await getCollegeList(currentPage, limit, stateName, districtName, debouncedSearchTerm);
        if (data) {
          setCollege(data.college);
          setTotalPages(data.totalPages);
          setTotalResults(data.totalResults);
        }
      } catch (error) {
        console.error("Error fetching college data:", error);
      } finally {
        setLoading(false);
      }
    };

    getCollegeData();
  }, [currentPage, stateName, districtName, debouncedSearchTerm]);

  const handlePageChange = (page: number) => {
    if (totalPages !== null && page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCurrentPage(1);
  };

  if (loading) {
    return (
      <div className="bg-[#111] h-screen flex justify-center items-center">
        <div className="text-white text-[3vh]">Loading... </div>
        <div className="flex flex-row justify-center items-center">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[110vh] bg-[#111] p-[2.5vh]">
      <h1 className="text-[2.9vh] font-semibold text-white text-left my-[2vh]">
        College Dashboard
      </h1>

      {/* <form onSubmit={handleSearch} className="mb-[2vh]">
        <input
          type="text"
          placeholder="Search by College Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mr-[1vh] p-[1vh] border border-[#4f4f4f] rounded"
        />
        <input
          type="text"
          placeholder="Filter by State Name"
          value={stateName}
          onChange={(e) => setStateName(e.target.value)}
          className="mr-[1vh] p-[1vh] border border-[#4f4f4f] rounded"
        />
        <input
          type="text"
          placeholder="Filter by District Name"
          value={districtName}
          onChange={(e) => setDistrictName(e.target.value)}
          className="mr-[1vh] p-[1vh] border border-[#4f4f4f] rounded"
        />
        <button type="submit" className="bg-[#fff333] p-[1vh] rounded">
          Search
        </button>
      </form> */}

      <table className="p-[1vh] border-2 border-[#4f4f4f] w-[100%] mb-[2vh]">
        <thead>
          <tr className="bg-[#fff333] text-black">
            <th className="p-[1.5vh] border-2 border-[#4f4f4f] text-start">Sr No</th>
            <th className="p-[1.5vh] border-2 border-[#4f4f4f] text-start">College Name</th>
            <th className="p-[1.5vh] hidden sm:table-cell lg:table-cell border-2 border-[#4f4f4f] text-start">University Name</th>
          </tr>
        </thead>

        <tbody>
          {college.map((item, index) => (
            <tr key={index} className="border-2">
              <td className="text-start text-white py-[1.5vh] px-[2vh] border-2 border-[#4f4f4f]">
                {index + currentPage * limit - limit + 1}
              </td>
              <td className="text-start text-white px-[2vh] border-2 border-[#4f4f4f]">
                {item.collegeName}
              </td>
              <td className="text-start text-white px-[2vh] border-2 border-[#4f4f4f]">
                {item.universityName}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="my-[2.5vh]">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages || 1}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Colleges;
