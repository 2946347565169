import React from "react";
import axios from "axios";
import { apiUrl } from "../utils/apiConfig";
import { CategoryListResponse } from "../types/category";

export const getCategoryInterest = async (): Promise<CategoryListResponse> => {
  try {
    const response = await axios.get(`${apiUrl}/category`);
    if (response.data.success) {
      return {
        results: response.data.data.results,
        page: response.data.data.page,
        limit: response.data.data.limit,
        totalResults: response.data.data.totalResults,
        totalPages: response.data.data.totalPages,
      };
    } else {
      throw new Error("Failed to fetch category interest");
    }
  } catch (error) {
    console.error("Error fetching category interest:", error);
    throw new Error("Failed to fetch category interest");
  }
};
