import { apiUrl } from "../utils/apiConfig";
import Cookies from "js-cookie";
import axios from "axios";
import { DiscussionData } from "../types/discussions";
import { ApiResponse } from "../types/discussions";
import toast from "react-hot-toast";

export const getAllDiscussions = async (
  page: number,
  limit: number
): Promise<DiscussionData | null> => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.get(`${apiUrl}/discussion`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        page,
        limit,
      },
    });

    if (response.data.success) {
      const { results, page, limit, totalResults, totalPages } =
        response.data.data;
      return {
        results,
        page,
        limit,
        totalResults,
        totalPages,
      };
    }

    return null;
  } catch (error) {
    console.error("Error fetching discussions:", error);
    return null;
  }
};

export const getDiscussionAllComments = async (
  discussionId: string,
  page: number
) => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.get(
      `${apiUrl}/discussion/comment/${discussionId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          page,
        },
      }
    );

    if (response.data.success) {
      const { results, page, limit, totalResults, totalPages } =
        response.data.data;
      return {
        results,
        page,
        limit,
        totalResults,
        totalPages,
      };
    }
  } catch (error) {
    console.error("Error fetching comments:", error);
    return null;
  }
};

export const addCommentToDiscussion = async (
  discussionId: string,
  comment: string
) => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.post(
      `${apiUrl}/discussion/comment/${discussionId}`,
      {
        comment,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success) {
      toast.success("Comment added successfully");
      return response.data.data;
    } else {
      toast.error(response.data.message || "Failed to add comment");
      throw new Error(response.data.message || "Failed to add comment");
    }
  } catch (error:any) {
    const errorMessage =
      error.response?.data?.message || "Error adding comment";
    toast.error(errorMessage);
    throw new Error(errorMessage);
  }
};
