import React from "react";
import axios from "axios";
import { apiUrl } from "../utils/apiConfig";
import Cookies from "js-cookie";
import { OutingData, Attendee, Request } from "../types/outings";

export const getOutingsListbyCategory = async (
  categoryId: string,
  userLatitude: number,
  userLongitude: number
): Promise<OutingData[] | undefined> => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.get(
      `${apiUrl}/outing/getOutingListByCategory/${categoryId}/${userLatitude}/${userLongitude}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success) {
      console.log("Outings by category", response.data.data);
      return response.data.data;
    }
  } catch (error) {
    console.error("Error fetching outings by category", error);
  }
};

export const getOutingDetailsByID = async (id: string) => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.get(
      `${apiUrl}/outing/getOutingDetailsById/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.data.success) {
      return response.data.data;
    }
  } catch (error) {
    console.error("Error fetching outing details", error);
  }
};

export const getOutingAttendeesDetails = async (
  id: string
): Promise<Attendee[]> => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.get(
      `${apiUrl}/outing/getOutingPublicDetailsById/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success) {
      return response.data.data.attendees as Attendee[];
    } else {
      console.error("API response did not indicate success");
      return [];
    }
  } catch (error) {
    console.error("Error fetching outing attendees", error);
    return [];
  }
};

export const getOutingsRequestDetails = async (
  id: string
): Promise<Request[]> => {
  try {
    const accessToken = Cookies.get("access_token");
    const response = await axios.get(
      `${apiUrl}/outing/getOutingDetailsAndRequestsById/${id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("access_token")}`,
        },
      }
    );

    if (response.data.success) {
      return response.data.data.requests as Request[];
    } else {
      console.error("API response did not indicate success");
      return [];
    }
  } catch (error) {
    return [];
  }
};