import React, { useEffect, useState } from "react";
import { getCategoryInterest } from "../../services/getCategoryInterest";
import LoadingComponent from "../../components/Loading";
import { Category } from "../../types/category";

const CategoryDashboard: React.FC = () => {

  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);

      try {
        const data = await getCategoryInterest();
        setCategories(data.results);
        // console.log("Category fetched", data);
      } catch (error) {
        setError("Failed to load categories");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="h-auto p-[2.5vh] ">
      <h1 className="text-[3.5vh] font-semibold text-white text-left">
        This is Category Dashboard
      </h1>

      <div className="my-[3vh] mb-[10vh]">
        {categories.map((category) => (
          <div key={category.id} className="my-[2vh]">
            <div className="bg-[#2b2b2b6b] px-[3vh] py-[1vh] rounded-lg">
              <h1 className="text-[2.9vh] my-[2vh] font-medium text-[#c0c0c0]">
                {category.title}
              </h1>
              <div className="flex flex-row justify-start items-center gap-x-[3vh]">
                <div className="w-[15%]  flex flex-col justify-center items-center bg-[#ffffff21] rounded-full p-[2vh]">
                  <img 
                    src={category.icon} 
                    className="w-[90%] h-[90%] object-contain" 
                  />
                </div>
                <div className="w-full flex flex-row flex-wrap gap-[2vh]">
                  {category.interests.map((interest)=>(
                    <div key={interest.id} className="border-2 hover:scale-110 hover:bg-[#fff333]  cursor-pointer transition-all text-[#fff133b3] hover:text-[#111] duration-300 border-[#fff133b3] px-[2vh] py-[1vh] rounded-full">
                      <p className=" font-medium text-[2.4vh]">
                        {interest.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryDashboard;