import React, { useEffect, useState } from "react";
import LoadingComponent from "../../components/Loading";
import {
  getAllDiscussions,
  getDiscussionAllComments,
  addCommentToDiscussion,
} from "../../services/Discussion";
import { Discussion, DiscussionData, Comment } from "../../types/discussions";
import account from "../../assets/account.png";
import { formatDateTime } from "../../utils/dateTime";
import { capitalizeFirstLetter } from "../../utils/Capitalize";
import Pagination from "../../components/Pagination";
import SendIcon from "@mui/icons-material/Send";
import toast from "react-hot-toast";

const DiscussionDashboard = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [discussions, setDiscussions] = useState<DiscussionData | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [limit, setLimit] = useState<number>(10);
  const [error, setError] = useState<string | null>(null);

  const [createNewDiscussion, setCreateNewDiscussion] =
    useState<boolean>(false);

  const [comment, setComment] = useState<string>("");

  const [selectedDiscussion, setSelectedDiscussion] = useState<string | null>(
    null
  );
  const [comments, setComments] = useState<Comment[] | null>(null);
  const [viewComments, setViewComments] = useState<boolean>(false);
  const [commentsPage, setCommentsPage] = useState<number>(1);
  const [hasMoreComments, setHasMoreComments] = useState<boolean>(true);

  const defaultPfpUrl =
    "https://d1rht7mb7jlq6e.cloudfront.net/assets/default-pfp.png";

  useEffect(() => {
    const fetchDiscussions = async () => {
      setLoading(true);
      try {
        const data = await getAllDiscussions(currentPage, limit);
        if (data) {
          setDiscussions(data);
          setTotalPages(data.totalPages);
        }
      } catch (error) {
        console.error("Error fetching discussion data:", error);
        setError("Error fetching discussion data.");
      } finally {
        setLoading(false);
      }
    };

    fetchDiscussions();
  }, [currentPage]);

  const fetchComments = async (discussionId: string, page: number) => {
    try {
      const fetchedComments = await getDiscussionAllComments(
        discussionId,
        page
      );
      if (fetchedComments) {
        setComments((prevComments) =>
          prevComments
            ? [...prevComments, ...fetchedComments.results]
            : fetchedComments.results
        );
        setHasMoreComments(fetchedComments.results.length > 0);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleAddComment = async () => {
    if (selectedDiscussion && comment.trim()) {
      setLoading(true);
      try {
        const newComment = await addCommentToDiscussion(
          selectedDiscussion,
          comment
        );
        if (newComment) {
          setComments((prevComments) =>
            prevComments ? [newComment, ...prevComments] : [newComment]
          );
          setComment("");
          setViewComments(false);
        }
      } catch (error: any) {
        console.error("Error adding comment:", error.message);
        setViewComments(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleViewComments = async (discussionId: string) => {
    setViewComments(true);
    setSelectedDiscussion(discussionId);
    setCommentsPage(1);
    setComments(null);
    await fetchComments(discussionId, 1);
  };

  const loadMoreComments = async () => {
    if (selectedDiscussion && hasMoreComments) {
      const nextPage = commentsPage + 1;
      await fetchComments(selectedDiscussion, nextPage);
      setCommentsPage(nextPage);
    }
  };

  const toggleViewComments = () => {
    setViewComments(!viewComments);
  };

  const toggleCreateNewDiscussion = () => {
    setCreateNewDiscussion(!createNewDiscussion);
  };

  const handlePageChange = (page: number) => {
    if (totalPages !== null && page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="h-auto px-[2.5vh] pt-[3vh]">
      <div className="flex flex-row justify-between items-center mb-[3vh]">
        <h1 className="text-[3vh] font-medium text-[#c0c0c0] text-left ">
          This is Discussion Dashboard
        </h1>

        <div className="flex flex-row gap-x-[2vh] items-center">
          <h1 className="sidekick text-white text-[2.8vh] mr-[1vh] font-semibold">
            Total Results: {discussions?.totalResults}
          </h1>
          <button
            onClick={toggleCreateNewDiscussion}
            className="bg-[#fff333] p-[1vh] hover:scale-110 transition-all duration-300"
          >
            <p className="sidekick text-[#111] text-[2.1vh] font-semibold">
              Create New
            </p>
          </button>
        </div>
      </div>

      {discussions?.results.map((discussion, index) => (
        <div
          key={index}
          className="p-[2.5vh] relative my-[2vh] rounded-lg bg-[#2b2b2b6b] "
        >
          <div className="flex flex-row justify-between items-center">
            <p className="text-[#787878] font-semibold text-[2.3vh] mb-[2vh]">
              <span>Created by {discussion?.created_by.fullName} </span>
              <span className="text-[#fff333]">
                @{discussion?.created_by.username}
              </span>
            </p>
            <p
              className={`${
                discussion?.discussion_type === "public"
                  ? "bg-[#007d24]"
                  : "bg-[#fd6500]"
              } hover:scale-110 transition-all cursor-pointer duration-300 text-white text-[2.1vh] font-semibold px-[2vh] py-[0.5vh]`}
            >
              {capitalizeFirstLetter(discussion?.discussion_type)}
            </p>
          </div>
          <div className="flex flex-row justify-between items-start gap-x-[3vh] mb-[3vh]">
            <img
              src={
                discussion?.created_by.profilePic.imageUrl === defaultPfpUrl
                  ? account
                  : discussion?.created_by.profilePic.imageUrl
              }
              className="w-[7vh] h-[7vh] object-cover rounded-full"
            />
            <h1 className="w-[100%] text-wrap text-[3vh] text-white">
              {discussion?.title}
            </h1>
            <div></div>
          </div>

          <div className="mb-[3vh]">
            <p className="text-[#fff333] font-medium text-[2.3vh] my-[1vh]">
              Top Comments
            </p>
            {discussion?.comments.map((comment, index) => (
              <div
                className="flex flex-row items-center gap-x-[2vh] my-[1vh]"
                key={index}
              >
                <img
                  src={
                    comment.user?.profilePic.imageUrl === defaultPfpUrl
                      ? account
                      : comment.user?.profilePic.imageUrl
                  }
                  alt=""
                  className="w-[5vh] h-[5vh] rounded-full object-cover"
                />
                <div>
                  <p className="text-[2.1vh] text-[#bababa] font-semibold">
                    {comment.user?.fullName}
                  </p>
                  <p className="text-[2vh] text-[#bababa]">{comment.comment}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-row justify-between items-center">
            <p className="text-[#c2c2c2] font-medium">
              Posted on {formatDateTime(discussion?.createdAt)}
            </p>

            <button
              onClick={() => handleViewComments(discussion?._id)}
              className="font-semibold hover:scale-110 transition-all cursor-pointer duration-300 text-[2.1vh] px-[2vh] py-[0.5vh] bg-[#fff333] rounded-full "
            >
              View Comments
            </button>
          </div>
        </div>
      ))}

      <div className="mt-[2vh] mb-[5vh]">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages || 1}
          onPageChange={handlePageChange}
        />
      </div>

      {viewComments && selectedDiscussion && (
        <div
          data-aos="fade-left"
          className="fixed overflow-y-auto overflow-x-hidden inset-0 w-full h-full backdrop-blur-sm bg-opacity-50 flex justify-end items-center transition-opacity duration-300 ease-in-out"
          onClick={(e) => {
            if (e.target === e.currentTarget) toggleViewComments();
          }}
        >
          <div
            data-aos="fade-left"
            data-aos-duration="800"
            className="relative bg-[#100e00] w-[32vw] lg:h-full overflow-y-scroll px-[5vh] rounded-lg overflow-x-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="sticky top-0 bg-[#100e00] pt-[3vh] pb-[3vh] z-10 border-b-2 border-[#4f4f4f]">
              <button
                onClick={toggleViewComments}
                className="absolute -top-[0vh] right-[0vh] m-[2vh] bg-[#f00] px-[2vh] py-[1vh] rounded-lg hover:bg-red-700 transition-colors duration-300 ease-in-out"
              >
                <p className="text-[2.4vh] font-extrabold text-white">X</p>
              </button>
              <h1 className="text-[3vh] text-center text-[#bbbbbb] ">
                All Comments
              </h1>
            </div>

            <div className="mb-[5vh] mt-[2vh]">
              {comments?.map((comment, index) => (
                <div className="flex flex-row gap-x-[2vh] my-[3vh]" key={index}>
                  <div className="">
                    <img
                      src={
                        comment.user?.profilePic.imageUrl === defaultPfpUrl
                          ? account
                          : comment.user?.profilePic.imageUrl
                      }
                      alt="User"
                      className="w-[7vh] h-[7vh] rounded-full object-cover"
                    />
                  </div>

                  <div className="w-[82%]">
                    <p className="text-[2.1vh] text-[#bababa] font-bold">
                      {comment.user?.fullName}
                    </p>
                    <p className="text-[2vh] text-[#bababa]">
                      {comment.comment}
                    </p>
                  </div>
                </div>
              ))}

              {hasMoreComments && (
                <button
                  onClick={loadMoreComments}
                  className="font-semibold flex flex-row justify-center items-center mx-auto hover:scale-110 transition-all cursor-pointer duration-300 text-[2.1vh] px-[2vh] py-[0.5vh] bg-[#fff333] rounded-full mb-[3vh] "
                >
                  Load More
                </button>
              )}
            </div>

            <div className="sticky bottom-0 z-20 pb-[2vh] bg-[#100e00]">
              <div className="flex flex-row justify-between items-center p-[1vh] border-[#4f4f4f] border-2 bg-[#1a1a1a]">
                <input
                  type="text"
                  placeholder="Add a comment..."
                  className="w-[100%] bg-transparent text-[#fff333] focus:outline-none rounded-lg"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <button
                  onClick={handleAddComment}
                  className="bg-[#fff333] p-[1vh] rounded-lg"
                >
                  {loading ? <div className="spinner"></div> : <SendIcon />}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {createNewDiscussion && (
        <div
          data-aos=""
          className="fixed overflow-y-auto overflow-x-hidden inset-0 w-full h-full backdrop-blur-sm bg-opacity-50 flex justify-center items-center transition-opacity duration-300 ease-in-out"
        >
          <div
            data-aos="zoom-in"
            data-aos-duration="800"
            className="relative bg-[#0f0f0f] w-[42vw] lg:h-[60vh]  px-[5vh] rounded-lg overflow-x-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="sticky top-0 pt-[3vh] pb-[3vh] z-10 ">
              <button
                onClick={toggleCreateNewDiscussion}
                className="absolute -top-[0vh] right-[0vh] m-[2vh] bg-[#f00] px-[2vh] py-[1vh] rounded-lg hover:bg-red-700 transition-colors duration-300 ease-in-out"
              >
                <p className="text-[2.4vh] font-extrabold text-white">X</p>
              </button>
              <h1 className="text-[3vh] text-center text-[#bbbbbb] ">
                What's on your mind today?
              </h1>
            </div>

            <div className="w-full">
              <input
                type="text"
                className="border-b-2 border-[#fff333] text-[2.3vh] w-full bg-transparent focus:outline-none py-[1vh] mt-[3vh] mb-[3vh] text-[#fff333] "
                placeholder="Start a discussion..."
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscussionDashboard;
