import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../utils/encryptToken';

interface ProtectedRoutesProps {
    element: JSX.Element;
}

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ element }) => {
    const access_token = Cookies.get('access_token');
    const location = useLocation();

    if(access_token){
        return location.pathname === "/login" ? <Navigate to="/"/>: <>{element}</>;
    };

    return location.pathname === "/login" ? <>{element}</>: <Navigate to="/login"/>
};

export default ProtectedRoutes;