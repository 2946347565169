import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { apiUrl } from "../utils/apiConfig";
import axios from "axios";
import { User, UserDetail, ApiResponse } from "../types/user";

const AdminUserDetails = () => {
  const [user, setUser] = useState<User | null>(null);
  const [userDetail, setUserDetail] = useState<UserDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setError(null);

      try {
        const token = Cookies.get("access_token");
        if (token) {
          const response = await axios.get(`${apiUrl}/user/my-profile`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          if (response.data && response.data.success) {
            setUser(response.data.data.user);
            setUserDetail(response.data.data.userDetail);
          } else {
            setError("No user data found.");
          }
        } else {
          setError("No access token found.");
        }
      } catch (error) {
        console.error("Failed to fetch user details", error);
        setError("Failed to fetch user details.");
        Cookies.remove("access_token");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  return { user,userDetail, loading, error };

};

export default AdminUserDetails;
