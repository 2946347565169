export function simplifyBuncoinsRepresentation(buncoins: number) {
	const suffixes = ["", "K", "M", "B", "T"];
	let suffixIndex = 0;

	while (buncoins >= 1000 && suffixIndex < suffixes.length - 1) {
		buncoins /= 1000;
		suffixIndex++;
	}

	if (buncoins % 1 === 0) {
		return buncoins.toFixed(0) + suffixes[suffixIndex];
	} else {
		const formattedNum = buncoins.toFixed(2);
		return formattedNum.replace(/\.?0+$/, '') + suffixes[suffixIndex];
	}
}